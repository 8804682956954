enum FeatureFlags {
  LEAD_2860_USE_MULTIPLE_SUMINSURANCE_VALUE_FOR_PACKAGE_LISTING_20221206_TEMP = 'lead-2860_use-multiple-suminsurance-value-for-package-listing_20221206_temp',

  ORDER_2470_USER_API_REPLACE_OLD_ENDPOINT_20231102_TEMP = 'order-2470_user-api-replace-old-endpoint-20231102_temp',
  ORDER_2497_HIDE_CANCEL_BUTTON_FOR_SALES_AGENT_20231106_TEMP = 'order-2497_hide-cancel-button-for-sales-agent_20231106_temp',

  PAYM_2407_SHOW_MOCK_DATA_20230126_TEMP = 'paym-2407_show-mock-data_20230126_temp',
  PAYM_2775_UPDATE_FOLLOWUP_DUE_DATE_20240205_TEMP = 'paym-2775_update-followup-due-date_20240205_temp',
  PAYM_2723_USE_NEW_CONTRACT_HISTORY_API_20240508_TEMP = 'paym_2723_use_new_contract_history_api_20240508_temp',
  PAYM_2822_ENABLE_USER_LICENSE_ANNOTATIONS_FIELDS_20240516_TEMP = 'paym-2822_enable-user-license-annotations-fields_20240516_temp',
  PAYM_2883_ALLOW_CUSTOMER_PHONE_NUMBER_TO_DELETE_20230612_TEMP = 'paym_2883_allow-customer-phone-number-to-delete_20230612_temp',
  PAYM_2906_SHOW_HISTORY_CHARGE_TABLES_20240701_TEMP = 'paym_2906_show-history-charge-tables_20240701_temp',
  PAYM_2936_HIDE_CHANGE_BUTTON_IF_ALREADY_CANCELLED_20240712_TEMP = 'paym_2936_hide-change-button-if-already-cancelled_20240712_temp',
  PAYM_2953_BLOCK_REJECT_LEAD_IF_ORDER_PURCHASED_20240724_TEMP = 'paym-2953_block-reject-lead-if-order-purchased_20240724_temp',
  PAYM_2957_SHOW_CREDIT_AMOUNT_20240725_TEMP = 'paym-2957_show-credit-shell-amount_20240725_temp',
  PAYM_2971_SHOW_REFUND_COLUMN_20240807_TEMP = 'paym-2971_show-refund-column_20240807_temp',

  HT_36_ENABLE_TRAVEL_ORDER_PAGE_20230521_TEMP = 'ht_36_enable_travel_order_page_20230521_temp',

  BROK_35_ENABLE_ACCOUNTING_PAGES_20240715_TEMP = 'brok-35_enable-accounting-pages_20240715_temp',
  BROK_122_UPDATE_CUSTOMER_ON_UPDATE_OF_QC_POLICY_20240808_TEMP = 'brok-122_update-customer-on-update-of-qc-policy_20240808_temp',
  BROK_177_ENABLE_ALL_ACCOUNTING_PAGE_20240815_TEMP = 'brok_177_enable_all_accounting_page_20240815_temp',

  TC_483_SHOW_CALL_STATISTICS_FOR_LEAD_AND_ORDER_TEMP_20220920 = 'tc-483_show_call_statistics_for_lead_and_order_temp_20220920',
  PAYM_2396_ENABLE_NEW_CREATE_PAYMENT = 'paym-2396_enable_new_create_payment',
  ORDER_2628_UPDATE_DOCUMENT_MAPPING_20240328_TEMP = 'order-2628_update-document-mapping_20240328_temp',
  PAYM_2841_ADD_HEALTH_TRAVEL_PRODUCT_OPTIONS_20240518_TEMP = 'paym-2841_add-health-travel-product-options_20240518_temp',
  HT_56_ADD_HEALTH_TRAVEL_OPTION_DROPDOWN_20240521_TEMP = 'ht-56_add-health-travel-option-dropdown_20240521_temp',
  PAYM_2902_FE_PARTIAL_CHANGE_ORDER_20240626_TEMP = 'paym-2902_fe-partial-change-order_20240626_temp',
  BROK_38_CANCELLATION_CHANGEORDER_FIELD_20240724_TEMP = 'brok-38_cancellation-changeorder-field_20240724_temp',
  BROK_112_CANCELLATION_REASON_NEW_OPTIONS_20240803_TEMP = 'brok-112_cancellation_reason_new_options_20240803_temp',
  BROK_258_ACTUAL_REFUND_AMOUNT_20240828_TEMP = 'brok-258_actual_refund_amount_20240828_temp',
  BROK_243_SWITCH_CANCELLATION_ENDPOINT_20240905_temp = 'brok-243_switch_cancellation_endpoint_20240905_temp',
  BROK_510_AI_TRANSCRIBES_20241022_TEMP = 'brok-510-ai_transcribes_20241022_temp',
  BROK_210_ENABLE_ORDER_LISTING_PAGE_241024_TEMP = 'brok_210_enable_order_listing_page_241024_temp',
  BROK_782_ALLOW_ONLY_SALES_AGENT_TO_PURCHASE_29102024_TEMP = 'brok_782_allow-only-sales-agent-to-purchase_29102024_temp',
  BROK_625_ENABLE_TRANSACTION_LISTING_PAGE_20241113_TEMP = 'brok-625_enable-transaction-listing-page_20241113_temp',
  BROK_902_ADD_NEW_CONFIG_GROUP_IN_AUTO_ASSIGN_20241113_TEMP = 'brok-904_add-new-config-group-in-auto-assign_20241113_temp',
  BROK_316_HIDE_PRODUCT_COLUMN_ON_LEAD_IMPORT_PAGE_20241119_TEMP = 'brok-316_hide-product-column-on-lead-import-page_20241119_temp',
  BROK_446_ENABLE_CAMPAIGN_PAGE_FOR_HEALTH_20241211_TEMP = 'brok-446_enable-campaign-page-for-health_20241211_temp',
  BROK_1007_IS_NEW_PACKAGE_LISTING_PAGE_ENABLED_20241120_TEMP = 'brok-1007_is-new-package-listing-page_20241120_temp',
  BROK_1138_SHOW_PAYMENT_FLOW_FOR_HEALTH_20241210_TEMP = 'brok-1138_show-payment-flow-for-health_20241210_temp',
  BROK_1208_ENABLE_CONTRACT_PAGE_FOR_HEALTH_20241213_TEMP = 'brok-1208_enable-conctract-page-for-health_20241213_temp',
  BROK_1052_ENABLE_PRODUCT_DROPDOWN_ON_LEAD_SOURCE_MODAL_20241223_TEMP = 'brok-1052_enable-product-dropdown-on-lead-source-modal_20241223_temp',
}

export default FeatureFlags;
