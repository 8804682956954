import { ArrowLeftIcon, DownloadFileIcon } from '@alphafounders/icons';
import {
  Button,
  Container,
  DisclaimerSection,
  InfoContainer,
  InfoLeadCar,
  InfoSection,
} from '@alphafounders/ui';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { useGetLeadByIDQuery } from 'data/slices/leadSlice';
import {
  addToComparison,
  usePackagesForComparison,
} from 'data/slices/packageListing';
import { useGetSuccessfulTransactionQuery } from 'data/slices/transactionSlice';
import Loader from 'presentation/components/Loader';
import NotFound from 'presentation/components/NotFound';
import useAuthorizedUsers from 'presentation/pages/car-insurance/PackageListingPage/hooks/useAuthorizedUsers';
import {
  isValidLead,
  generateLendingApiPayload,
  generateDiscountPricingApiPayload,
  isPackageSelected,
} from 'presentation/pages/car-insurance/PackageListingPage/packageListing.helper';
import { showSnackBar } from 'presentation/redux/actions/ui';
import { getPackagesUrl } from 'presentation/routes/Urls';
import { getString } from 'presentation/theme/localization';
import * as CONSTANTS from 'shared/constants';
import { InsuranceKind } from 'shared/types/insurers';

import InsurerHeader from './InsurerHeader';
import { getFilterFromQueryParam } from './translatePackage.helper';
import useGetPackageData from './useGetPackageData';
import useTranslatePackageData from './useTranslatePackageData';

import { isMotorLead } from 'presentation/pages/car-insurance/LeadDetailsPage/leadDetailsPage.helper';
import useGenerateQuotation from 'presentation/pages/car-insurance/PackageListingPage/hooks/useGenerateQuotation';
import usePackageStorage from 'presentation/pages/car-insurance/PackageListingPage/hooks/usePackageStorage';
import useSelectPackage from 'presentation/pages/car-insurance/PackageListingPage/hooks/useSelectPackage';
import { isHealthLead } from 'presentation/pages/health-insurance/leads/leadDetailsPage/helper';

function PackageDetailPage() {
  const enableMultipleSuminsured = false;

  const disableOnSuccessfulTransaction = false;

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const isPackageDetailView = queryParams.has('id');
  const filterValues = getFilterFromQueryParam(queryParams);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: lead } = useGetLeadByIDQuery(params.id!);
  const { data: transactions, isLoading: transactionLoading } =
    useGetSuccessfulTransactionQuery(lead?.name ?? '', {
      skip:
        !lead?.name || !disableOnSuccessfulTransaction || !isPackageDetailView,
    });
  const selectedPackageId = lead?.data?.checkout?.package ?? '';

  const orderId = `${getString('text.orderId')}: ${lead?.humanId || ''}`;

  const {
    packages,
    sumInsuredInfo,
    isLoading: packageLoading,
    carDetails,
  } = useGetPackageData(
    isPackageDetailView ? [queryParams.get('id') ?? ''] : [],
    filterValues
  );

  const [packageDetail] = packages;

  const { generateQuotation, isLoading } = useGenerateQuotation();

  // old redux storage
  const packagesForComparison = usePackagesForComparison();

  // new session storage
  const {
    packageData: packagesForComparisonNew,
    addToComparison: addForComparisonNew,
  } = usePackageStorage();

  const translatedPackageData = useTranslatePackageData(false, packageDetail);

  const { isLoading: isAuthorizedUserLoading, isUserAllowed } =
    useAuthorizedUsers(lead?.name);

  const [selectPackage, { isLoading: isSelectPackageLoading }] =
    useSelectPackage(params.id!);

  const goBack = () => {
    if (isPackageDetailView) {
      navigate(getPackagesUrl(lead?.name.split('/')[1]));
    } else {
      navigate(`/${lead?.name as string}`);
    }
  };

  const handleSelect = async () => {
    const {
      paymentOption: customPaymentOption,
      paymentMethod: customPaymentMethod,
      numberOfInstallments: customInstallments,
    } = packageDetail?.customQuoteDetail ?? {};

    const selectPackagePayload = {
      leadId: params.id!,
      payload: generateLendingApiPayload({
        package: packageDetail.id,
        insuranceKind: packageDetail.insuranceKind,
        sumInsuredMin: filterValues.sumInsured?.min,
        sumInsuredMax: filterValues.sumInsured?.max,
        paymentOption: customPaymentOption || filterValues?.paymentOption,
        paymentMethod: customPaymentMethod || undefined,
        installment: customInstallments || filterValues?.installment,
      }),
    };

    const response = await selectPackage(selectPackagePayload);
    if ('error' in response) {
      dispatch(
        showSnackBar({
          isOpen: true,
          message: getString('errorMessage.generalErrorMessage'),
          status: CONSTANTS.snackBarConfig.type.error,
        })
      );
    }
  };

  const handleCompare = () => {
    if (enableMultipleSuminsured) {
      addForComparisonNew({
        detail: packageDetail,
        sumInsuredMax: filterValues.sumInsured?.max,
        sumInsuredMin: filterValues.sumInsured?.min,
        insuranceCategory: filterValues.insuranceKind,
        name: packageDetail.id,
      });
    } else {
      dispatch(addToComparison(packageDetail.id));
    }
    navigate(getPackagesUrl(lead?.name.split('/')[1]));
  };

  const {
    paymentOption: customPaymentOption,
    paymentMethod: customPaymentMethod,
    numberOfInstallments: customInstallments,
  } = packageDetail?.customQuoteDetail ?? {};

  const {
    paymentOption: checkoutPaymentOption,
    installments: checkoutInstallments,
  } = lead?.data?.checkout ?? {};

  const paymentDetails = useMemo(() => {
    if (packageDetail?.customQuoteDetail) {
      return {
        paymentOption: customPaymentOption,
        paymentMethod: customPaymentMethod,
        installment: customInstallments,
      };
    }

    return {
      paymentOption: isPackageDetailView
        ? filterValues.paymentOption
        : checkoutPaymentOption,
      installment: isPackageDetailView
        ? filterValues.installment
        : checkoutInstallments,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    packageDetail?.customQuoteDetail,
    isPackageDetailView,
    filterValues.paymentOption,
    filterValues.installment,
    lead?.data?.checkout,
  ]);

  const handleDownloadQuotation = () => {
    const quotationPayload = generateDiscountPricingApiPayload([
      {
        package: packageDetail.id,
        insuranceKind: isPackageDetailView
          ? packageDetail.insuranceKind
          : (lead?.data?.insuranceKind as InsuranceKind),
        sumInsuredMin: isPackageDetailView
          ? filterValues.sumInsured?.min
          : sumInsuredInfo.min,
        sumInsuredMax: isPackageDetailView
          ? filterValues.sumInsured?.max
          : sumInsuredInfo.max,
        ...paymentDetails,
      },
    ]);

    generateQuotation({
      leadId: `leads/${params.id}`,
      carInsuranceQuotationFilter: quotationPayload,
    });
  };

  if (packageLoading || isAuthorizedUserLoading || transactionLoading) {
    return <Loader />;
  }

  if (
    !packageDetail ||
    !isUserAllowed ||
    (!isMotorLead(lead) && !isHealthLead(lead)) ||
    transactions?.charges?.length
  ) {
    return (
      <NotFound
        text={getString('errorPage.packageNotFound')}
        redirectTo={`${isHealthLead(lead) ? '/health' : ''}/leads/${params.id}`}
        btnText={getString('errorPage.goToLead')}
      />
    );
  }

  return (
    <div className="w-full h-full bg-white">
      <Container className="grid grid-cols-4">
        <div>
          <button
            className="py-5 px-2 flex items-center bg-white border-none"
            type="button"
            onClick={goBack}
          >
            <ArrowLeftIcon />
            <span className="ml-2">{getString('text.back')}</span>
          </button>
        </div>
        <div className="col-span-2 text-center pb-20">
          <InsurerHeader
            insurancePackage={packageDetail}
            isSelected={isPackageSelected(packageDetail, selectedPackageId)}
            isSelectedForComparison={
              enableMultipleSuminsured
                ? packagesForComparisonNew
                    .map((pkg) => pkg.name)
                    .includes(packageDetail.id)
                : packagesForComparison.includes(packageDetail.id)
            }
            onSelect={handleSelect}
            isSelectLoading={isSelectPackageLoading}
            onCompare={handleCompare}
            showButtons={isValidLead(lead) && isPackageDetailView}
          />
          {packageDetail?.insuranceKind === 'both' ? (
            <div>
              <p className="my-1 text-success font-bold">
                {getString('packageListing.includingCompulsoryPrice')}
              </p>
            </div>
          ) : null}
          <br />
          <br />
          <InfoLeadCar
            title={`${getString('packageListing.showingPackageDetailsFor')}:`}
            orderId={orderId}
            carDetails={carDetails || null}
          />
          <Button
            dataTestId="downloadQuotationButton-packageDetailPage"
            text={
              isLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                getString('packageListing.downloadQuotation')
              )
            }
            onClick={handleDownloadQuotation}
            icon={<DownloadFileIcon className="mr-2" />}
            variant="secondary"
            className="p-2 m-3 text-base mx-auto h-[25px] min-w-[190px] hover:bg-primary-light"
            disabled={isLoading}
          />
          <br />
          <br />
          <InfoContainer>
            {translatedPackageData.map((section) => (
              <InfoSection key={section.key} data={section} />
            ))}
            <DisclaimerSection
              listStyleImage={`${process.env.PUBLIC_URL}/static/img/rabbit-heart-logo.svg`}
              title={getString('remark.title')}
              description={getString('remark.description')}
            />
          </InfoContainer>
          <Button
            dataTestId="downloadQuotationButton-packageDetailPage"
            text={
              isLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                getString('packageListing.downloadQuotation')
              )
            }
            onClick={handleDownloadQuotation}
            icon={<DownloadFileIcon className="mr-2" />}
            variant="secondary"
            className="p-2 m-3 text-base mx-auto mt-10 h-[25px] min-w-[190px] hover:bg-primary-light"
            disabled={isLoading}
          />
        </div>
      </Container>
    </div>
  );
}

export default PackageDetailPage;
