import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ChatwootInboxEmbedded() {
  const { t } = useTranslation();

  const chatwootUrl = import.meta.env.VITE_CHATWOOT_URL;

  return (
    chatwootUrl && (
      <div className="shadow-lg border border-solid border-neutral-200 fixed right-0 bottom-0 z-40 w-[65rem] h-[calc(100vh-7rem)] bg-white has-[:checked]:translate-y-0 translate-y-full transition-all duration-300 ease-in-out">
        <input type="checkbox" id="chat-toggle" className="hidden" />
        <label
          htmlFor="chat-toggle"
          className="absolute -top-7 right-10 z-50 cursor-pointer bg-primary text-white rounded-t-md uppercase font-medium px-6 py-1 hover:bg-primary/90"
        >
          {t('widget.chatWithCustomer')}
        </label>
        <iframe
          title="Chatwoot"
          className="w-full h-full border-0"
          src={chatwootUrl}
        />
      </div>
    )
  );
}
